/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BooleanResponse } from '../models/boolean-response';
import { BroadcastMessagesResponse } from '../models/broadcast-messages-response';
import { BroadcastThreadContactsCountResponse } from '../models/broadcast-thread-contacts-count-response';
import { BroadcastThreadResponse } from '../models/broadcast-thread-response';
import { BroadcastThreadsResponse } from '../models/broadcast-threads-response';
import { ContactTagLookupModel } from '../models/contact-tag-lookup-model';
import { MediaModel } from '../models/media-model';
import { messageBroadcastingDeleteBroadcastById } from '../fn/message-broadcasting/message-broadcasting-delete-broadcast-by-id';
import { MessageBroadcastingDeleteBroadcastById$Params } from '../fn/message-broadcasting/message-broadcasting-delete-broadcast-by-id';
import { messageBroadcastingDownloadMedia } from '../fn/message-broadcasting/message-broadcasting-download-media';
import { MessageBroadcastingDownloadMedia$Params } from '../fn/message-broadcasting/message-broadcasting-download-media';
import { messageBroadcastingGetBroadcastThread } from '../fn/message-broadcasting/message-broadcasting-get-broadcast-thread';
import { MessageBroadcastingGetBroadcastThread$Params } from '../fn/message-broadcasting/message-broadcasting-get-broadcast-thread';
import { messageBroadcastingGetBroadcastThreadContactsCount } from '../fn/message-broadcasting/message-broadcasting-get-broadcast-thread-contacts-count';
import { MessageBroadcastingGetBroadcastThreadContactsCount$Params } from '../fn/message-broadcasting/message-broadcasting-get-broadcast-thread-contacts-count';
import { messageBroadcastingGetBroadcastThreadMessages } from '../fn/message-broadcasting/message-broadcasting-get-broadcast-thread-messages';
import { MessageBroadcastingGetBroadcastThreadMessages$Params } from '../fn/message-broadcasting/message-broadcasting-get-broadcast-thread-messages';
import { messageBroadcastingGetBroadcastThreads } from '../fn/message-broadcasting/message-broadcasting-get-broadcast-threads';
import { MessageBroadcastingGetBroadcastThreads$Params } from '../fn/message-broadcasting/message-broadcasting-get-broadcast-threads';
import { messageBroadcastingGetMyMessagingPhoneNumbers } from '../fn/message-broadcasting/message-broadcasting-get-my-messaging-phone-numbers';
import { MessageBroadcastingGetMyMessagingPhoneNumbers$Params } from '../fn/message-broadcasting/message-broadcasting-get-my-messaging-phone-numbers';
import { messageBroadcastingGetUnsubscribedNumbersList } from '../fn/message-broadcasting/message-broadcasting-get-unsubscribed-numbers-list';
import { MessageBroadcastingGetUnsubscribedNumbersList$Params } from '../fn/message-broadcasting/message-broadcasting-get-unsubscribed-numbers-list';
import { messageBroadcastingLookupContactsAndTags } from '../fn/message-broadcasting/message-broadcasting-lookup-contacts-and-tags';
import { MessageBroadcastingLookupContactsAndTags$Params } from '../fn/message-broadcasting/message-broadcasting-lookup-contacts-and-tags';
import { messageBroadcastingPinConversationByGroup } from '../fn/message-broadcasting/message-broadcasting-pin-conversation-by-group';
import { MessageBroadcastingPinConversationByGroup$Params } from '../fn/message-broadcasting/message-broadcasting-pin-conversation-by-group';
import { messageBroadcastingSaveBroadcastThread } from '../fn/message-broadcasting/message-broadcasting-save-broadcast-thread';
import { MessageBroadcastingSaveBroadcastThread$Params } from '../fn/message-broadcasting/message-broadcasting-save-broadcast-thread';
import { messageBroadcastingSendMessage } from '../fn/message-broadcasting/message-broadcasting-send-message';
import { MessageBroadcastingSendMessage$Params } from '../fn/message-broadcasting/message-broadcasting-send-message';
import { messageBroadcastingStreamMedia } from '../fn/message-broadcasting/message-broadcasting-stream-media';
import { MessageBroadcastingStreamMedia$Params } from '../fn/message-broadcasting/message-broadcasting-stream-media';
import { messageBroadcastingUploadMedia } from '../fn/message-broadcasting/message-broadcasting-upload-media';
import { MessageBroadcastingUploadMedia$Params } from '../fn/message-broadcasting/message-broadcasting-upload-media';
import { SmsPhoneNumberModel } from '../models/sms-phone-number-model';
import { UnsubscribedListResponse } from '../models/unsubscribed-list-response';

@Injectable({ providedIn: 'root' })
export class MessageBroadcastingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `messageBroadcastingSendMessage()` */
  static readonly MessageBroadcastingSendMessagePath = '/api/message-broadcasting/send-broadcast';

  /**
   * Send Broadcast.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingSendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingSendMessage$Response(params: MessageBroadcastingSendMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanResponse>> {
    return messageBroadcastingSendMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Send Broadcast.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingSendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingSendMessage(params: MessageBroadcastingSendMessage$Params, context?: HttpContext): Observable<BooleanResponse> {
    return this.messageBroadcastingSendMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingUploadMedia()` */
  static readonly MessageBroadcastingUploadMediaPath = '/api/message-broadcasting/upload-media';

  /**
   * Upload Media.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingUploadMedia()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  messageBroadcastingUploadMedia$Response(params: MessageBroadcastingUploadMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaModel>> {
    return messageBroadcastingUploadMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload Media.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingUploadMedia$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  messageBroadcastingUploadMedia(params: MessageBroadcastingUploadMedia$Params, context?: HttpContext): Observable<MediaModel> {
    return this.messageBroadcastingUploadMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaModel>): MediaModel => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingGetMyMessagingPhoneNumbers()` */
  static readonly MessageBroadcastingGetMyMessagingPhoneNumbersPath = '/api/message-broadcasting/my-messaging-phone-numbers';

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingGetMyMessagingPhoneNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingGetMyMessagingPhoneNumbers$Response(params?: MessageBroadcastingGetMyMessagingPhoneNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SmsPhoneNumberModel>>> {
    return messageBroadcastingGetMyMessagingPhoneNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingGetMyMessagingPhoneNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingGetMyMessagingPhoneNumbers(params?: MessageBroadcastingGetMyMessagingPhoneNumbers$Params, context?: HttpContext): Observable<Array<SmsPhoneNumberModel>> {
    return this.messageBroadcastingGetMyMessagingPhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SmsPhoneNumberModel>>): Array<SmsPhoneNumberModel> => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingSaveBroadcastThread()` */
  static readonly MessageBroadcastingSaveBroadcastThreadPath = '/api/message-broadcasting/save-broadcast';

  /**
   * Save Broadcast Thread.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingSaveBroadcastThread()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingSaveBroadcastThread$Response(params: MessageBroadcastingSaveBroadcastThread$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanResponse>> {
    return messageBroadcastingSaveBroadcastThread(this.http, this.rootUrl, params, context);
  }

  /**
   * Save Broadcast Thread.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingSaveBroadcastThread$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingSaveBroadcastThread(params: MessageBroadcastingSaveBroadcastThread$Params, context?: HttpContext): Observable<BooleanResponse> {
    return this.messageBroadcastingSaveBroadcastThread$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingGetBroadcastThreads()` */
  static readonly MessageBroadcastingGetBroadcastThreadsPath = '/api/message-broadcasting/threads/{phoneNumber}';

  /**
   * Get broadcasting groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingGetBroadcastThreads()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingGetBroadcastThreads$Response(params: MessageBroadcastingGetBroadcastThreads$Params, context?: HttpContext): Observable<StrictHttpResponse<BroadcastThreadsResponse>> {
    return messageBroadcastingGetBroadcastThreads(this.http, this.rootUrl, params, context);
  }

  /**
   * Get broadcasting groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingGetBroadcastThreads$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingGetBroadcastThreads(params: MessageBroadcastingGetBroadcastThreads$Params, context?: HttpContext): Observable<BroadcastThreadsResponse> {
    return this.messageBroadcastingGetBroadcastThreads$Response(params, context).pipe(
      map((r: StrictHttpResponse<BroadcastThreadsResponse>): BroadcastThreadsResponse => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingGetBroadcastThread()` */
  static readonly MessageBroadcastingGetBroadcastThreadPath = '/api/message-broadcasting/broadcast/{id}';

  /**
   * Get broadcasting group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingGetBroadcastThread()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingGetBroadcastThread$Response(params: MessageBroadcastingGetBroadcastThread$Params, context?: HttpContext): Observable<StrictHttpResponse<BroadcastThreadResponse>> {
    return messageBroadcastingGetBroadcastThread(this.http, this.rootUrl, params, context);
  }

  /**
   * Get broadcasting group.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingGetBroadcastThread$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingGetBroadcastThread(params: MessageBroadcastingGetBroadcastThread$Params, context?: HttpContext): Observable<BroadcastThreadResponse> {
    return this.messageBroadcastingGetBroadcastThread$Response(params, context).pipe(
      map((r: StrictHttpResponse<BroadcastThreadResponse>): BroadcastThreadResponse => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingGetBroadcastThreadContactsCount()` */
  static readonly MessageBroadcastingGetBroadcastThreadContactsCountPath = '/api/message-broadcasting/broadcast-contacts-count/{id}';

  /**
   * Get broadcasting contacts count.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingGetBroadcastThreadContactsCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingGetBroadcastThreadContactsCount$Response(params: MessageBroadcastingGetBroadcastThreadContactsCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BroadcastThreadContactsCountResponse>> {
    return messageBroadcastingGetBroadcastThreadContactsCount(this.http, this.rootUrl, params, context);
  }

  /**
   * Get broadcasting contacts count.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingGetBroadcastThreadContactsCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingGetBroadcastThreadContactsCount(params: MessageBroadcastingGetBroadcastThreadContactsCount$Params, context?: HttpContext): Observable<BroadcastThreadContactsCountResponse> {
    return this.messageBroadcastingGetBroadcastThreadContactsCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BroadcastThreadContactsCountResponse>): BroadcastThreadContactsCountResponse => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingGetBroadcastThreadMessages()` */
  static readonly MessageBroadcastingGetBroadcastThreadMessagesPath = '/api/message-broadcasting/get-messages';

  /**
   * Get broadcast summary messages.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingGetBroadcastThreadMessages()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingGetBroadcastThreadMessages$Response(params: MessageBroadcastingGetBroadcastThreadMessages$Params, context?: HttpContext): Observable<StrictHttpResponse<BroadcastMessagesResponse>> {
    return messageBroadcastingGetBroadcastThreadMessages(this.http, this.rootUrl, params, context);
  }

  /**
   * Get broadcast summary messages.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingGetBroadcastThreadMessages$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingGetBroadcastThreadMessages(params: MessageBroadcastingGetBroadcastThreadMessages$Params, context?: HttpContext): Observable<BroadcastMessagesResponse> {
    return this.messageBroadcastingGetBroadcastThreadMessages$Response(params, context).pipe(
      map((r: StrictHttpResponse<BroadcastMessagesResponse>): BroadcastMessagesResponse => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingDeleteBroadcastById()` */
  static readonly MessageBroadcastingDeleteBroadcastByIdPath = '/api/message-broadcasting/broadcast/{broadcastId}';

  /**
   * Delete Broadcast.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingDeleteBroadcastById()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingDeleteBroadcastById$Response(params: MessageBroadcastingDeleteBroadcastById$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return messageBroadcastingDeleteBroadcastById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Broadcast.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingDeleteBroadcastById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingDeleteBroadcastById(params: MessageBroadcastingDeleteBroadcastById$Params, context?: HttpContext): Observable<boolean> {
    return this.messageBroadcastingDeleteBroadcastById$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingGetUnsubscribedNumbersList()` */
  static readonly MessageBroadcastingGetUnsubscribedNumbersListPath = '/api/message-broadcasting/unsubscribe-list';

  /**
   * Get unsbscribed numbers list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingGetUnsubscribedNumbersList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingGetUnsubscribedNumbersList$Response(params: MessageBroadcastingGetUnsubscribedNumbersList$Params, context?: HttpContext): Observable<StrictHttpResponse<UnsubscribedListResponse>> {
    return messageBroadcastingGetUnsubscribedNumbersList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get unsbscribed numbers list.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingGetUnsubscribedNumbersList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingGetUnsubscribedNumbersList(params: MessageBroadcastingGetUnsubscribedNumbersList$Params, context?: HttpContext): Observable<UnsubscribedListResponse> {
    return this.messageBroadcastingGetUnsubscribedNumbersList$Response(params, context).pipe(
      map((r: StrictHttpResponse<UnsubscribedListResponse>): UnsubscribedListResponse => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingPinConversationByGroup()` */
  static readonly MessageBroadcastingPinConversationByGroupPath = '/api/message-broadcasting/pin-conversation/{groupKey}';

  /**
   * Pin Conversation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingPinConversationByGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingPinConversationByGroup$Response(params: MessageBroadcastingPinConversationByGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return messageBroadcastingPinConversationByGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Pin Conversation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingPinConversationByGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingPinConversationByGroup(params: MessageBroadcastingPinConversationByGroup$Params, context?: HttpContext): Observable<boolean> {
    return this.messageBroadcastingPinConversationByGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingLookupContactsAndTags()` */
  static readonly MessageBroadcastingLookupContactsAndTagsPath = '/api/message-broadcasting/lookup-contacts';

  /**
   * Lookup contacts and tags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingLookupContactsAndTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingLookupContactsAndTags$Response(params: MessageBroadcastingLookupContactsAndTags$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContactTagLookupModel>>> {
    return messageBroadcastingLookupContactsAndTags(this.http, this.rootUrl, params, context);
  }

  /**
   * Lookup contacts and tags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingLookupContactsAndTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingLookupContactsAndTags(params: MessageBroadcastingLookupContactsAndTags$Params, context?: HttpContext): Observable<Array<ContactTagLookupModel>> {
    return this.messageBroadcastingLookupContactsAndTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactTagLookupModel>>): Array<ContactTagLookupModel> => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingStreamMedia()` */
  static readonly MessageBroadcastingStreamMediaPath = '/api/message-broadcasting/stream-media/*';

  /**
   * Stream Media.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingStreamMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingStreamMedia$Response(params?: MessageBroadcastingStreamMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return messageBroadcastingStreamMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * Stream Media.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingStreamMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingStreamMedia(params?: MessageBroadcastingStreamMedia$Params, context?: HttpContext): Observable<void> {
    return this.messageBroadcastingStreamMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingDownloadMedia()` */
  static readonly MessageBroadcastingDownloadMediaPath = '/api/message-broadcasting/download-media/*';

  /**
   * Download Media.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingDownloadMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingDownloadMedia$Response(params?: MessageBroadcastingDownloadMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return messageBroadcastingDownloadMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * Download Media.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingDownloadMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingDownloadMedia(params?: MessageBroadcastingDownloadMedia$Params, context?: HttpContext): Observable<void> {
    return this.messageBroadcastingDownloadMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
